.globalWrapper {
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    background: #fff;
    overflow-y: auto;
}

.docsContainer {

}

.listItem {
    padding: 12px;
    cursor: pointer;
}

.listItem:hover {
    background-color: #DBE4FF;
}

.listItemIcon {
    width: 64px;
    min-width: 64px;
    height: 64px;
    min-height: 64px;
    background-size: contain;

}

.listItemName {
    font-weight: 600;
    font-size: 17px;
}

.listItemDescription {
    font-size: 14px;
    color: #808080;
}

.mt-3 {
    margin-top: 3px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-20 {
    margin-top: 20px;
}

.fz-20 {
    font-size: 20px;
}
