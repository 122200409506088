.d-flex {
    display: flex;
}

.fd-rr {
    flex-direction: row-reverse;
}

.fd-column {
    flex-direction: column;
}

.jc-center {
    justify-content: center;
}

.jc-sa {
    justify-content: space-around;
}

.jc-sb {
    justify-content: space-between;
}

.ai-center {
    align-items: center;
}

.w-ft {
    max-width: fit-content;
}

.max-w-180 {
    max-width: 180px;
}

.max-w-250 {
    max-width: 250px;
}

.m-0 {
    margin: 0 !important;
}

.w-100p {
    width: 100%;
}

.m-0-auto {
    margin: 0 auto !important;
}

.mv-4 {
    margin-top: 4px;
    margin-bottom: 4px;
}

.mv-7 {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
}

.mv-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.mh-8 {
    margin-left: 8px;
    margin-right: 8px;
}

.ml-13 {
    margin-left: 13px;
}

.ml-5 {
    margin-left: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.pl-12 {
    padding-left: 12px;
}

.pl-20 {
    padding-left: 20px;
}

.pt-12 {
    padding-top: 12px;
}

.pb-8 {
    padding-bottom: 8px;
}

.pb-16 {
    padding-bottom: 16px;
}

.mt-12 {
    margin-top: 12px;
}

.ml-auto {
    margin-left: auto;
}

.ml-10 {
    margin-left: 10px;
}

.ml-20 {
    margin-left: 20px;
}

.pr-12 {
    padding-right: 12px;
}

.cursor-pointer {
    cursor: pointer;
}

.fz-15 {
    font-size: 15px;
}

.no-select {
    user-select: none !important;
}

.ta-center {
    text-align: center !important;
}

.color-red {
    color: var(--colorRed) !important;
}